// ####### Declarations #########
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';

import { useFullState } from '../../../shared/utils/hooks';
import Layzestload from '../../../vendors/lazyestload/lazyestload';
import Api from '../../album/api';

// ########### Styled Components ###########

const Photo = styled.img`
  display: block;
  width: calc(100% * ${props => props.size} / 100);
  border: solid 4px #fff;
  overflow: hidden;

  &.lazyestload {
    filter: blur(16px);
  }
`;

const PhotosBox = styled.div`
  display: flex;
  width: 1080px;
  margin-bottom: 24px;
`;

const PageContainer = styled.section`
  display: flex;
  flex-direction: column;
  border: solid 4px #fff;
  float: right;
`;

// ########### Composing view ###########
const SharedAlbum = () => {
  const [state, setState] = useFullState({
    ready: false,
    photos: []
  });

  useEffect(() => {
    const fetchData = async () => {
      const res = await Api.getAllPortfolioImages();

      // Just for prototype
      if (res.success) {
        const rows = res.message.assigned;

        for (const [i, row] of rows.entries()) {
          for (const [j, photo] of row.entries()) {
            const detailsResponce = await Api.getPhoto({ id: photo.id });

            if (detailsResponce.success) {
              const photoDetails = detailsResponce.message;

              const ratio = parseFloat((photoDetails.thumbnail.max.meta.width / photoDetails.thumbnail.max.meta.height * 100).toFixed(4));

              photoDetails.ratio = ratio;
              photo.details = photoDetails;
              photo.row = i;
              photo.column = j;
            }
          }
        }

        const images = res.message.assigned;

        setState({
          ready: true,
          photos: images.map((imgs) => {
            const totalColumnSize = imgs.reduce((ar, an) => ar + an.details.ratio, 0);

            return imgs.map((image) => {
              const size = parseFloat((image.details.ratio / totalColumnSize * 100).toFixed(4));
              return { ...image, details: { ...image.details, size } };
            });
          })
        });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    Layzestload();
  }, []);

  if (!state.ready) {
    return null;
  }

  return (
    <PageContainer className="animated fadeIn">
      <h3>Photography</h3>
      {state.photos.map((photos, i) => (
        <PhotosBox key={i}> { /* eslint-disable-line react/no-array-index-key */}
          {
            photos.map(photo => (
              <Photo alt="" key={photo.id} src={photo.details.thumbnail.min.path} data-src={photo.details.file.path} size={photo.details.size} className="lazyestload" />
            ))
          }
        </PhotosBox>
      ))}
    </PageContainer>
  );
};

export default withRouter(SharedAlbum);
