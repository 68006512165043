// ####### Declarations ##########
import React, { useEffect, useState } from 'react';

import { Route, Switch, Redirect } from 'react-router-dom';
import { map, get, isEmpty } from 'lodash';
import { dConnect } from '../shared/utils/helpers';
import { CreateReduxComponent } from '../self/component';
import { setUserData } from '../actions/user';

import AuthApi from '../core/api/auth';
import UserApi from '../core/api/APIsettings';
import App from '../core/app';

import Dropzone from 'shared/ui/dropzone/dropzone';

// Modules
import Album from './album/routes';
import Dashboard from './dashboard/routes';

import Root from './root/root';
import Auth from './auth/auth';
import Settings from './settings/settings';
import e404 from './default/404';
import Profile from './profile/profile';
import AlbumSettings from './album/views/settings/settings';

// Module Paths
import Routes from './routes';

// ########### Composing view ###########

const Modules = (props) => {
  const isLoggedIn = AuthApi.checkSession();
  const isUserData = !isEmpty(props.app.user.details);

  useEffect(() => {
    const loadUser = async () => {
      const userData = await UserApi.get();

      props.dispatch(setUserData(userData.message));
    };

    if (isLoggedIn) {
      loadUser();
    }
  }, []);

  if (isLoggedIn && !isUserData) {
    return null;
  }

  return (
    <Dropzone dropzoneEnabled={AuthApi.checkSession()}>
      {
        () => (
          <Switch>
            {
              map(Routes.AuthRoutePath, (route, index) => <Route key={index} exact path={route} component={Auth} />)
            }
            <Route exact path={Routes.AlbumRoutePath} component={Album} />
            <Route exact path={Routes.RootRoutePath} component={Root} />
            <Route exact path={Routes.DashboardRoutePath} component={Dashboard} />
            <Route exact path={Routes.SettingsPath} component={Settings} />
            <Route exact path={Routes.ProfilePath} component={Profile} />
            <Route exact path={Routes.AlbumSettingsPath} component={AlbumSettings} />

            <Route component={e404} />
          </Switch>
        )
      }
    </Dropzone>
  );
};

export default dConnect(Modules);
