// ####### Declarations ##########
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';

import Authenticate from 'components/authenticate/authenticate';
import AppLayout from 'components/layouts/default/default';

import { CreateReduxComponent } from '../../self/component';
import { dConnect } from '../../shared/utils/helpers';
import styled from 'styled-components';
import Dialog from '../../shared/ui/modal/modal';

// views
import AboutView from './views/about/about';
import ContactView from './views/contact/contact';
import ArtworksView from './views/artworks/artworks';
import OverviewView, { Approved } from './views/overview/overview';
import ProfileManager from '../profileManager/profileManager';

// ########### Styled Components ############
const Profession = styled.h3`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
  font-size: 0.875rem;
  font-weight: 600;
  color: #757575;
`;
const Name = styled.h2`
  margin: 0;
  font-weight: 800;

  display: flex;
  align-items: center;
`;
const TopSectionBox = styled.div`
`;
const AboutUser = styled.div`
  margin-bottom: 19px;
`;
const PortfolioFrameBox = styled.section`
  // margin: -2rem;
  // padding: 1rem;
  // background: #eee;
`;
const PortfolioContainer = styled.section`
  // padding: 2rem;
  // background: #fff;
  // border-radius: 15px;
  display: flex;
`;
const FollowButton = styled.button`
  background: #2da75e;
  color: #fff;
  border: none;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  padding: 0 22px;
  border-radius: 38px;
  margin: 0 1.33rem;
  position: relative;
  top: 1px;
  left: 0px;
  border: solid 2px #2da75e;

  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 700;
  line-height: 38px;
  outline: none;
`;
const UnfollowButton = styled.button`
  background: #fff;
  border: solid 2px #a72d2d;
  color: #a72d2d;
  height: 40px;
  font-size: 15px;
  font-weight: 600;
  padding: 0 22px;
  border-radius: 38px;
  margin: 0 1.33rem;
  position: relative;
  top: 1px;
  left: 0px;

  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
  font-weight: 700;
  line-height: 38px;
  outline: none;
`;
const SideMenuBox = styled.aside`
  max-width: 200px;
  width: 100%;
  display: block;
  height: 100px;
`;
const ContentBox = styled.main`
  width: 100%;
`;
const UL = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  padding-right: 2em;

  ${props => props.fixed && `
    position: fixed;
    top: 2rem;
  `}
`;
const LI = styled.li`
  margin: 0.875rem 0;
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;

  ${props => props.active && `
    font-weight: 700;

    &:before {
      content: ''
    }
  `}
`;
const Separator = styled.li`
  letter-spacing: 2px;
  font-size: 0.66rem;
  font-weight: 600;
  color: #757575;
  position: relative;
  top: 2px;
  margin-bottom: 2.875rem;
  margin-top: 3px;
`;
const P = styled.p`
  text-align: center;
  font-size: 1.2rem;
  color: #767676;
  width: 500px;
  left: 0;
  right: 0;
  margin: auto;
  margin-top: 10px;
`;
// ######################################

const Follow = () => {
  const [following, setFollowing] = useState(false);

  if (!following) {
    return (
      <FollowButton onClick={() => setFollowing(true)}>Follow</FollowButton>
    );
  }

  return (
    <UnfollowButton onClick={() => setFollowing(false)}>Unollow</UnfollowButton>
  );
};

const Sidemenu = (props) => {
  const [fixed, setFixed] = useState(window.pageYOffset >= 81);

  useEffect(() => {
    const onScroll = (e) => {
      if (window.pageYOffset >= 81) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <SideMenuBox>
      <UL fixed={fixed}>
        <Separator>MENU</Separator>
        <LI onClick={() => props.setState({ context: 0 })} active={props.active === 0}>Overview</LI>
        <LI onClick={() => props.setState({ context: 1 })} active={props.active === 1}>About</LI>
        <LI onClick={() => props.setState({ context: 2 })} active={props.active === 2}>Projects</LI>
        <LI onClick={() => props.setState({ context: 3 })} active={props.active === 3}>Graphic Design</LI>
        <LI onClick={() => props.setState({ context: 4 })} active={props.active === 4}>Contact</LI>
      </UL>
    </SideMenuBox>
  );
};

// ########### Composing view ###########
class Profile extends CreateReduxComponent {
  constructor(props) {
    super(props);

    this.state = {
      context: 3,
      dialogOpen: false
    };
  }

  getUserDetails() {
    return (
      <AboutUser className="animated fadeIn">
        <Profession>Photographer <Approved /></Profession>
        <Name>{this.props.app.user.details.first_name} {this.props.app.user.details.last_name} <Follow /></Name>
      </AboutUser>
    );
  }

  getViewContext() {
    const header = (
      <TopSectionBox>
        {this.getUserDetails()}
      </TopSectionBox>
    );

    if (this.state.context === 0) {
      return (
        <React.Fragment>
          {header}
          <OverviewView />
        </React.Fragment>
      );
    }

    if (this.state.context === 1) {
      return (
        <React.Fragment>
          {header}
          <AboutView />
        </React.Fragment>
      );
    }

    if (this.state.context === 2) {
      return (
        <React.Fragment>
          {header}
          <ArtworksView />
        </React.Fragment>
      );
    }

    if (this.state.context === 3) {
      return <ProfileManager />;
    }

    if (this.state.context === 4) {
      return (
        <React.Fragment>
          {header}
          <ContactView />
        </React.Fragment>
      );
    }

    if (this.state.context === 5) {
      return this.getArtworkDetail();
    }
  }

  render() {
    return (
      <React.Fragment>
        <AppLayout>
          <Authenticate>
            <PortfolioFrameBox>
              <PortfolioContainer>
                <Sidemenu setState={state => this.setState(state)} active={this.state.context} />
                <ContentBox>
                  {this.getViewContext()}
                </ContentBox>
              </PortfolioContainer>
            </PortfolioFrameBox>
            <Dialog open={this.state.dialogOpen} onDialogClick={() => this.setState(ps => ({ dialogOpen: !ps.dialogOpen }))} />
          </Authenticate>
        </AppLayout>
      </React.Fragment>
    );
  }
}

// ########### Export ###########
export default dConnect(Profile);
