import { useState, useEffect } from 'react';
import { isFunction, isBoolean } from 'lodash';

// ######### Default values for hooks ###########

const defaultKeyboardNavigationHandlers = { // for autocompletion :)
  onEscape: null,
  onArrowUp: null,
  onArrowDown: null,
  onArrowLeft: null,
  onArrowRight: null,
  onSpaceBar: null,
  onEnter: null
};

const defaultLockArrowsUsageKeys = ['ArrowUp', 'ArrowDown'];

// ######### Hooks definition ###################

const useFullState = (initialState) => { // https://reactjs.org/docs/hooks-reference.html#functional-updates <-- yellow note
  const [state, setStateBase] = useState(initialState);
  let scheduledCallback = null;

  const setState = (nextState, callback) => {
    scheduledCallback = callback;

    setStateBase(prevState => ({
      ...prevState,
      ...(isFunction(nextState) ? nextState(prevState) : nextState)
    }));
  };

  useEffect(() => {
    if (isFunction(scheduledCallback)) {
      scheduledCallback(state);
    }
  }, [scheduledCallback]);

  return [state, setState];
};

const useLockGlobalKeyboardNavigation = (dependency = false, keys = defaultLockArrowsUsageKeys) => { // when custom component use keyboard, we have to block window from usage them (to stop scrolling using keyboard)
  if (!isBoolean(dependency)) {
    console.error('useLockGlobalKeyboardNavigation hook can only toggle event listeners based on single boolean dependency. If your component need to lock arrows usage based on non-boolean value (eg. multiple state values) please pass the final result');
  }

  const lockArrowsUsage = e => keys.some(k => e.key === k) && e.preventDefault();
  const events = {
    add: () => window.addEventListener('keydown', lockArrowsUsage),
    remove: () => window.removeEventListener('keydown', lockArrowsUsage)
  };

  useEffect(() => {
    events.remove(); // make sure we have clear listener;

    if (dependency) { // add listener only when prop tells to do it
      events.add();
    }

    return events.remove;
  }, [dependency]);
};

const useKeyboard = (handlers = defaultKeyboardNavigationHandlers) => {
  const resolveKey = key => (key === ' ' ? 'SpaceBar' : key); // https://www.w3.org/TR/uievents-key/#keys-whitespace

  const onKeyUp = (e) => {
    const eventHandler = handlers[`on${resolveKey(e.key)}`]; // https://reactjs.org/docs/events.html#keyboard-events

    if (isFunction(eventHandler)) {
      eventHandler(e);
    }
  };

  return [{ onKeyUp }];
};


// ######### Hooks export #######################

export {
  useFullState,
  useLockGlobalKeyboardNavigation,
  useKeyboard
};
