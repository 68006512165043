// ####### Declarations ##########
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { defaultsDeep } from 'lodash';
import { format } from 'date-fns';
import Layzestload from '../../../vendors/lazyestload/lazyestload';
import defaultBg from '../../../assets/default-bg.png';
import { hexToRgb } from '../../utils/helpers';

// ########### Styled Components ###########
const AlbumBox = styled.div`
  width: 100%;
  background: #fff;
  border-radius: 19px 19px 11px 11px;
  box-shadow: 0 17px 60px -12px rgba(0,0,0,.25);
  border: solid 1px rgba(130, 130, 130, 0.16);
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  z-index: 1;

  &:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 130%;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  &:hover, &:focus {
    box-shadow: 0 25px 60px -12px ${props => props.shadow};
    transform: translateY(-1rem);
  }
`;
const AlbumContentBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding: 0.5rem;
  box-sizing: border-box;
`;
const PhotoBox = styled.div`
  width: 100%;
  height: 100%;
  background: #eee;
  border-radius: 11px 11px 4px 4px;
  overflow: hidden;
`;
const Photo = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

  &.lazyestload {
    filter: blur(8px);
  }

  &.empty {
    padding: 40px;
  }
`;
const Date = styled.h4`
  color: #2da75e;
  margin: 0;
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
`;
const Title = styled.h4`
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.25;
  margin-top: 2px;
`;
const Stats = styled.div`
  margin: 0;
  z-index: 1;
  font-size: 0.68rem;
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  padding: 3px 4px;
  background: #fff;
  border-radius: 6px;
  right: 14px;
  top: 14px;
  display: flex;
  align-items: center;
  box-shadow: 0px 3px 12px -1px rgba(0,0,0,.25);

  > i {
    font-size: 1rem;
    color: #2da75e;
  }

  > span {
    position: relative;
    top: 1px;
    margin-left: 2px;
    margin-right: 2px;
  }
`;
const StatsBox = styled.div`
  padding: 0.5rem;
  padding-bottom: 0.3rem;
  padding-top: 0.6rem;
`;

// ########### Defaults ################
const defaultProps = {
  cover: '',
  placeholder: defaultBg,
  title: '',
  color: '',
  photos: 0,
  date: ''
};

// ########### Composing view ###########
const Album = (props = defaultProps) => {
  const params = defaultsDeep({
    ...props,
    date: format(new window.Date(props.date), 'D MMM YYYY')
  }, defaultProps);

  const { r, g, b } = hexToRgb(params.color) || { r: 160, g: 160, b: 160 };

  useEffect(() => {
    Layzestload();
  }, []);

  return (
    <AlbumBox tabIndex={0} shadow={`rgb(${r}, ${g}, ${b})`}>
      <AlbumContentBox>
        <Stats>
          <i className="material-icons">photo</i>
          <span>{params.photos}</span>
        </Stats>
        <PhotoBox>
          <Photo src={params.placeholder} data-src={params.cover} className={params.cover ? 'lazyestload' : 'empty'} />
        </PhotoBox>
        <StatsBox>
          <Date>{params.date}</Date>
          <Title>{params.title}</Title>
        </StatsBox>
      </AlbumContentBox>
    </AlbumBox>
  );
};

export default Album;
