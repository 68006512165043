// ####### Declarations ##########
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { forEach, map, findIndex, find } from 'lodash';

import { dConnect } from '../../../../shared/utils/helpers';
import Dialog from '../../../../shared/ui/modal/modal';
import ButtonUI from '../../../../shared/ui/button/button';

import AlbumApi from '../../../album/api';
import { AboutCopy } from '../about/about';

// ########### Styled Components ############
const PhotosBox = styled.div`
  display: flex;
  // grid-template-rows: 1fr auto;
  // grid-gap: 1.5rem;
  // grid-template-columns: repeat(3, 1fr); //${props => Array(props.columns).fill('1fr').join(' ')};
  width: 100%;
  margin-top: 60px;
`;
const PhotosColumn = styled.div`
  img {
    width: 100%;
    display: block;
  }
`;
const PhotoWrapper = styled.div`
  border: solid 1px #eaeaea;
  margin: 0.5rem;
  margin-bottom: 1rem;
`;
const UserName = styled.div`
  margin: 0;
  margin-bottom: 2rem;
`;
const PhotoSwitchBox = styled.div`
  position: relative;
`;
const Button = styled.button`
  width: 30%;
  color: #fff;
  display: none;
  border: none;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
  outline: none;

  ${props => `
    text-align: ${props.dir};
    padding-${props.dir}: 1rem;
  `}

  > i {
    font-size: 3rem;
    width: 23px;

    ${props => props.dir === 'right' && `
      position: relative;
      left: -13px;
    `}
  }
`;
const NavigationBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  height: 100%;

  &:hover {
    > ${Button} {
      display: block;
    }
  }
`;
const Image = styled.img`
  ${props => (props.zoom ? `
    width: 100%;
    height: auto;
    cursor: zoom-out
  ` : `
    width: auto;
    height: calc(100vh - 2.5rem);
    cursor: zoom-in;
  `)}
  
  border-radius: 6px;
  left: 0;
  right: 0;
  margin: auto;
  display: block;
  border: solid 1px #eaeaea;
  position: relative;
  z-index: 1;
`;
const DialogHeader = styled.header`
  margin: -2rem;
  background: #fff;
  position: fixed;
  padding: 1rem;
  width: 100%;
  z-index: 1;
  top: 0;
  margin-bottom: 0;
  margin-top: 0;
  width: 1218px;
`;
// ########### Composing view ###########
const Artworks = (props) => {
  const [state, setState] = useState({
    ready: false,
    showDialog: false,
    artworks: [],
    currentPhoto: false,
    columns: 3
  });

  const PhotosSwitcher = () => {
    const photo = find(state.artworks, { id: state.currentPhoto });

    const move = (dir = 1) => {
      const last = dir === 1 ? { id: state.artworks[0].id } : { id: state.artworks[state.artworks.length - 1].id };

      setState({
        ...state,
        currentPhoto: (state.artworks[findIndex(state.artworks, { id: state.currentPhoto }) + dir] || last).id
      });
    };

    const ImageC = (p = {}) => {
      const [zoom, setZoom] = useState(false);

      return (
        <Image {...p} zoom={zoom} onClick={() => setZoom(!zoom)} />
      );
    };

    return (
      <PhotoSwitchBox>
        <NavigationBox>
          <Button dir="left" type="button" onClick={() => move(-1)}>
            <i className="material-icons">
              arrow_back_ios
            </i>
          </Button>
          <Button dir="right" type="button" onClick={() => move(1)}>
            <i className="material-icons">
              arrow_forward_ios
            </i>
          </Button>
        </NavigationBox>
        <ImageC alt="" src={photo.file.path} />
      </PhotoSwitchBox>
    );
  };


  const toggleDialog = (photoId = false) => {
    setState({
      ...state,
      currentPhoto: photoId,
      showDialog: !state.showDialog
    });
  };

  const onColumnsChange = (e) => {
    setState({
      ...state,
      columns: parseInt(e.target.value, 10)
    });
  };

  async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  useEffect(() => {
    const getArtworks = async () => {
      let { message: data } = await AlbumApi.getAllPortfolioImages();

      data = [...data, ...data.reverse(), ...data.reverse()];

      await asyncForEach(data, async (photo, i) => {
        const res = await AlbumApi.getPhoto({ id: photo.id });

        data[i] = {
          ...photo,
          ...res.message
        };
      });

      setState({
        ...state,
        ready: true,
        artworks: data
      });
    };

    getArtworks();
  }, []);

  if (!state.ready) {
    return null;
  }

  const imagesByColumn = {};

  forEach(state.artworks, (a, i) => {
    if (!imagesByColumn[i % state.columns]) {
      imagesByColumn[i % state.columns] = [];
    }

    imagesByColumn[i % state.columns].push(a);
  });

  return (
    <div>
      <PhotosBox className="animated fadeIn" columns={state.columns}>
        {
          map(imagesByColumn, (col, ckey) => (
            <PhotosColumn key={ckey}>
              {
                map(col, photo => (
                  <PhotoWrapper tabIndex="0" key={photo.id} onClick={() => toggleDialog(photo.id)}>
                    <img src={photo.thumbnail.max.path} alt="" />
                  </PhotoWrapper>
                ))
              }
            </PhotosColumn>
          ))
        }
      </PhotosBox>
      <Dialog open={state.showDialog} onDialogClick={toggleDialog}>
        <PhotosSwitcher />
        <div>
          <AboutCopy style={{
            left: '0',
            right: '0',
            margin: 'auto'
          }}>
            <h4>Photo title</h4>
            <h5>Experimenting with spread layout for an upcoming project.</h5>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Venenatis lectus magna fringilla urna porttitor rhoncus. Tellus pellentesque eu tincidunt tortor aliquam nulla facilisi cras fermentum. In nisl nisi scelerisque eu ultrices vitae auctor eu. Diam vulputate ut pharetra sit amet aliquam id diam maecenas. Eu augue ut lectus arcu bibendum at varius vel. Molestie at elementum eu facilisis sed odio morbi. Pretium quam vulputate dignissim suspendisse in est ante in nibh. Eget dolor morbi non arcu risus. Gravida in fermentum et sollicitudin ac. Interdum velit laoreet id donec ultrices tincidunt. Facilisi etiam dignissim diam quis enim lobortis.</p>
            <ButtonUI>Explore project <i className="material-icons">arrow_forward</i></ButtonUI>
          </AboutCopy>
        </div>
      </Dialog>
    </div>
  );
};

// ########### Export ###########
export default dConnect(Artworks);

/*
<div>
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5a3/81c/b8d/thumb_7_480_640_0_0_auto.jpg" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5c3/605/253/thumb_171_480_640_0_0_auto.png" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5b1/070/9c8/thumb_76_480_640_0_0_auto.jpg" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5c3/605/1fc/thumb_169_480_640_0_0_auto.png" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5c0/31a/78e/thumb_165_480_640_0_0_auto.png" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5ac/fe2/475/thumb_50_480_640_0_0_auto.jpg" />
</div>
<div>
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5c0/31a/71e/thumb_163_480_640_0_0_auto.png" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5c0/31a/66c/thumb_159_480_640_0_0_auto.png" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5c0/31a/6d0/thumb_161_480_640_0_0_auto.png" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5b1/070/9c8/thumb_76_480_640_0_0_auto.jpg" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5a3/81c/b8d/thumb_7_480_640_0_0_auto.jpg" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5c3/605/253/thumb_171_480_640_0_0_auto.png" />
</div>
<div>
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5c3/605/253/thumb_171_480_640_0_0_auto.png" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5b1/070/9c8/thumb_76_480_640_0_0_auto.jpg" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5a3/81c/b8d/thumb_7_480_640_0_0_auto.jpg" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5c3/605/253/thumb_171_480_640_0_0_auto.png" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5b1/070/9c8/thumb_76_480_640_0_0_auto.jpg" />
  <img alt="" onClick={toggleDialog} src="https://juliasabiniarz.com/storage/app/uploads/public/5a3/81c/b8d/thumb_7_480_640_0_0_auto.jpg" />
</div>

*/
